const postData = [
  {
    postNumber: "745823894403279",
  },
  {
    postNumber: "744436811208654",
  },
  {
    postNumber: "640662244919445",
  },
  {
    postNumber: "656968609790220",
  },
  {
    postNumber: "587662373552766",
  },
  {
    postNumber: "584646420521028",
  },

  {
    postNumber: "406014465050892",
  },
  {
    postNumber: "516935257292145",
  },
  {
    postNumber: "494926909290559",
  },
  {
    postNumber: "491159069869764",
  },
  {
    postNumber: "568598061962221",
  },
  {
    postNumber: "505783058407365",
  },
  {
    postNumber: "502468708738800",
  },
  {
    postNumber: "487487270236944",
  },
  {
    postNumber: "147400488068157",
  },
];
export default postData;
